import React, { useContext } from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import ReactHtmlParser from 'react-html-parser';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import {
  COLOR,
  BREAKPOINT,
  MARGIN,
  ALIGN
} from '@latitude/core/utils/constants';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { Link } from '@latitude/link';
import { Metadata } from '@latitude/metadata';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { Box } from '@latitude/box';
import Layout from '@/components/layout';
import { SITE_URL, TITLE_SUFFIX } from '@/utils/constants';
import HeroBranded from '../../../components/HeroBranded/HeroBranded';
import { Vertical } from '@latitude/spacing';
import { Lframe } from '@latitude/lframe';
import HowToApplyComponent from '@latitude/how-to-apply';
import { Heading3, Heading4, Heading6 } from '@latitude/heading';
import {
  PlanTypes,
  PLAN_TYPE_MINIMUM_MONTHLY_GEM,
  SimpleOfferTile,
  SimpleOffersContainer
} from '@latitude/interest-free-plans';
import { Masonry } from '@latitude/masonry';
import { List, ListItem } from '@latitude/list';
import { ImportantInformation } from '@latitude/important-information';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';
import { StaticImage } from 'gatsby-plugin-image';
import RatesAndFees from '../gemvisa/_rates-and-fees';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
const APPLY_URL = getUrlFromEnv('funnel-start-gemvisa-partners');
const title = ' Snooze now. Pay later.';
const description =
  'Big. Small. Shop it all. Shop for all your bedroom essentials Interest Free with a Latitude Gem Visa credit card.<br /><br />New customers must apply and be approved for a Latitude Gem Visa credit card. Available on participating Latitude credit cards only. Minimum spend applies. See details below.';
const metaDescription =
  'Big. Small. Shop it all. Shop for all your bedroom essentials Interest Free with a Latitude Gem Visa credit card. New customers must apply and be approved for a Latitude Gem Visa credit card. Available on participating Latitude credit cards only.';
const masonryData = {
  title: 'Why choose Latitude Interest Free? ',
  column1: {
    title: 'Latitude Gem Visa Credit Card',
    content: [
      {
        title: 'Flexible payment options ',
        description:
          'We partner with some of Australia’s best brands and retailers to give you access to Interest Free plans for all your larger purchases.'
      },
      {
        title: '6 months Interest Free',
        description:
          'Automatically get 6 months Interest Free when you spend $250 or more with your Latitude Gem Visa credit card.'
      },
      {
        title: 'Ready, set, tap',
        description:
          'Apply, get approved and start shopping from your phone - in minutes, thanks to our instant cardless payment feature.'
      },
      {
        title: 'View your spending 24/7',
        description:
          'Manage your payments and stay on top of your due dates with the Latitude App.'
      }
    ],
    cta: {
      label: 'Apply now',
      url: APPLY_URL
    }
  }
};
const SnoozeLandingPage = ({ location }) => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={location} noHeader noFooter>
      <Metadata
        title={`${title} | ${TITLE_SUFFIX}`}
        description={metaDescription}
        canonical={`${SITE_URL}${location.pathname}`}
      />
      {state?.heroBannerData?.[0] ? (
        <HeroBanner {...state?.heroBannerData[0]} />
      ) : (
        <Box backgroundColor={COLOR.GREY6}>
          <Lframe
            bgImage={
              require('./images/Snooze_hero_background-Optimised-v3.png').default
            }
          />
          <Box
            css={`
              @media (max-width: ${BREAKPOINT.MD}) {
                a {
                  margin-bottom: 40px;
                }
              }
            `}
          >
            <HeroBranded
              title={title}
              text={ReactHtmlParser(description)}
              buttonProps={{
                href: APPLY_URL,
                children: 'Apply for Latitude Gem Visa',
                trackId: 'loan-apply-now',
                button: 'primary',
                trackEventData: {
                  category: 'cta',
                  action: 'application-link'
                }
              }}
              trackProductId={['CCAUGEM']}
            />
          </Box>
        </Box>
      )}
      <StickyNavigation
        css={`
          position: relative;
          z-index: 1;
          @media (max-width: ${BREAKPOINT.LG}) {
            display: none;
          }
        `}
        items={[
          {
            anchor: 'shop-big',
            label: 'Shop big with a Latitude Gem Visa credit card'
          },
          {
            anchor: 'shop-interest-free',
            label: 'How to shop Interest Free'
          },
          {
            anchor: 'why-us',
            label: 'Why us?'
          },
          {
            anchor: 'rates-and-fees',
            label: 'Rates & Fees'
          }
        ]}
        offsetElem="[data-sticky-navigation-offset]"
        {...state?.inPageNavData?.[0]}
      />
      <div
        css={`
          .accordion .accordion__button-fixed {
            position: relative;
            top: auto !important;
          }
        `}
      >
        <Box.Section backgroundColor={COLOR.GREY6} id="shop-big">
          <Box backgroundColor={COLOR.GREY6}>
            <SimpleOffersContainer
              heading="Shop now with a Latitude Gem Visa credit card"
              description="Pay minimum monthly payments over 6-24 months and chip in extra where you can. Available in-store and online."
            >
              <SimpleOfferTile
                color="#FFEFF7"
                frequency="6 MONTHS"
                onPurchasesUpTo={500}
                trackTermsEventData={{
                  location: 'Shop now with a Latitude Gem Visa credit card'
                }}
                secondaryButtonScrollToId="important-info"
                product="gemvisa"
                interestFreeLabel="MINIMUM MONTHLY PAYMENT PLAN"
              />
              <SimpleOfferTile
                color="#FFEFF7"
                frequency="12 MONTHS"
                onPurchasesUpTo={750}
                trackTermsEventData={{
                  location: 'Shop now with a Latitude Gem Visa credit card'
                }}
                secondaryButtonScrollToId="important-info"
                product="gemvisa"
                interestFreeLabel="MINIMUM MONTHLY PAYMENT PLAN"
              />
              <SimpleOfferTile
                color="#FFEFF7"
                frequency="18 MONTHS"
                onPurchasesUpTo={999}
                trackTermsEventData={{
                  location: 'Shop now with a Latitude Gem Visa credit card'
                }}
                secondaryButtonScrollToId="important-info"
                product="gemvisa"
                interestFreeLabel="MINIMUM MONTHLY PAYMENT PLAN"
              />
              <SimpleOfferTile
                color="#FFEFF7"
                frequency="24 MONTHS"
                onPurchasesUpTo={999}
                trackTermsEventData={{
                  location: 'Shop now with a Latitude Gem Visa credit card'
                }}
                secondaryButtonScrollToId="important-info"
                product="gemvisa"
                interestFreeLabel="MINIMUM MONTHLY PAYMENT PLAN"
              />
            </SimpleOffersContainer>
          </Box>
        </Box.Section>
        <HorizontalRule />
        <Box
          id="shop-interest-free"
          backgroundColor={COLOR.GREY6}
          css={`
            padding: 48px 0;
            z-index: 1;
            @media (min-width: ${BREAKPOINT.LG}) {
              padding: 72px 0;
            }
          `}
        >
          <Heading4 color={COLOR.BLACK} align={ALIGN.CENTER} isResponsive>
            How to shop interest free
          </Heading4>
          <AnalyticsLocationProvider location="How to shop interest free">
            <HowToApplyComponent
              alignContent={true}
              box1={
                <Vertical spacing={MARGIN.M16}>
                  <Heading3
                    color={COLOR.BLACK}
                    align={ALIGN.CENTER}
                    isResponsive={false}
                  >
                    With a new Latitude Gem Visa credit card
                  </Heading3>
                  <Heading4
                    color={COLOR.BLACK}
                    align={ALIGN.CENTER}
                    isResponsive={false}
                  >
                    Apply now for a Latitude Gem Visa credit card
                  </Heading4>
                  <div css="margin:20px auto;">
                    <StaticImage
                      src="../../../images/credit-cards/latitude-gem-front.png"
                      alt="Interest free cards"
                      width={116}
                    />
                  </div>
                  <Heading6 marginBottom="8px">How to apply</Heading6>
                  <List>
                    <ListItem>Apply online in 10 minitues</ListItem>
                    <ListItem>
                      Once approved, simply activate your card online using the
                      Latitude app
                    </ListItem>
                    <ListItem>
                      <strong>In-store purchases:</strong> provide us with your
                      photo ID and account number
                    </ListItem>
                    <ListItem>
                      <strong>Online purchases:</strong> checkout using your
                      Latitude Gem Visa credit card account number and have your
                      mobile phone on hand for verification
                    </ListItem>
                  </List>
                  <Link
                    button="primary"
                    css="width: 200px; margin: 0 auto;"
                    href={`${APPLY_URL}?merchantID=000414939&channel=Online&source=0&stream=Upstream`}
                    data-trackid="apply-now"
                    trackEventData={{
                      category: 'cta',
                      action: 'application-link'
                    }}
                  >
                    Apply now
                  </Link>
                </Vertical>
              }
              box2={
                <Vertical spacing={MARGIN.M16}>
                  <Heading3
                    color={COLOR.BLACK}
                    align={ALIGN.CENTER}
                    isResponsive={false}
                  >
                    With your existing Latitude credit card
                  </Heading3>
                  <Heading4
                    color={COLOR.BLACK}
                    align={ALIGN.CENTER}
                    isResponsive={false}
                  >
                    Start shopping interest free now!
                  </Heading4>
                  <div css="margin:20px auto;">
                    <StaticImage
                      src="../../../images/credit-cards/interest-free-cards.webp"
                      alt="Interest free cards"
                      width={200}
                    />
                  </div>
                  <Heading6 marginBottom="8px">
                    How to shop interest free
                  </Heading6>
                  <List>
                    <ListItem>
                      For online purchases simply enter your card or account
                      number during the checkout process
                    </ListItem>
                    <ListItem>
                      Or visit your nearest store and present your card at
                      checkout
                    </ListItem>
                  </List>
                  <Link
                    button="primary"
                    css="width: 200px; margin: 0 auto;"
                    href="https://www.snooze.com.au"
                    data-trackid="start-shopping-now"
                  >
                    Start shopping now
                  </Link>
                </Vertical>
              }
            />
          </AnalyticsLocationProvider>
        </Box>
        <HorizontalRule />
        {state?.ratesAndFeesData?.[0] ? (
          <LabRatesAndFees {...state.ratesAndFeesData[0]} />
        ) : (
          <RatesAndFees />
        )}
        <div id="important-info">
          <ImportantInformation
            data={{
              content: {
                importantInformationSectionOne: [
                  '<strong>Minimum Monthly Payment Plan. Available on 6, 12, 18 & 24 months.</strong><br/><sup>*</sup>Approved customers only using a Minimum Monthly Payment Plan. Monthly credit card fee applies, currently $10.95  (subject to change). Minimum spend applies to different plan term(s): minimum spend $500 for 6 months, $750 for 12 months, $999 for 18 months and $999 for 24  months. Paying only the minimum monthly payments will not pay out the plan. After the interest free plan ends interest will be charged on any outstanding balance at the expired plan rate, currently 29.99% p.a. (subject to change). If you miss a payment late fees will apply and interest may apply. Interest may also be charged on other purchases and transactions made on your Latitude credit card. The Latitude Credit Card General Terms and Conditions set out the T&Cs for the Latitude Gem Visa credit card.',
                  '<strong>Online & In-Store Orders</strong><br/>If you have made a Latitude Interest Free order online, details of your monthly payment will appear on your next statement after you’ve made your order.'
                ],
                importantInformationSectionTwo: [
                  '<strong>General Terms and Conditions</strong><br/>This notice is given under the <a href="https://assets.latitudefinancial.com/legals/credit-card-general-terms-and-conditions.pdf" target="_blank">Latitude Credit Card General Terms and Conditions</a> (as applicable), which specify all other conditions for this offer. Credit is provided by Latitude Finance Australia ABN 42 008 583 588 Australian Credit Licence number 392145.',
                  'Visa and the Visa brand are registered trademarks of Visa International.',
                  '<sup>1</sup>&nbsp;To take advantage of up to 55 days interest free on credit card general purchases, you need to pay the full closing balance (excluding un-expiring 0% Interest Payment Plans) on each statement of account by the applicable due date.',
                  '<sup>®</sup>&nbsp;Registered to BPAY Pty Ltd ABN 69 079 137 518'
                ]
              }
            }}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            {...state?.importantInfoData?.[0]}
          />
        </div>
      </div>
    </Layout>
  )
};
export default SnoozeLandingPage;
